import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import {
  ACCENT_COLOR,
  ATTACHMENTS_AVAILABLE,
  CHAT_POSITION,
  CLOSE,
  COMPANY_PROPERTIES,
  CONTRAST_COLOR,
  FORCE_LOCALIZATION,
  HIDE,
  LOGOUT,
  ON_CHAT_CLOSED,
  ON_CHAT_OPENED,
  ON_HELP_CENTER_ARTICLE,
  ON_LEAD_GENERATED,
  ON_NEW_CONVERSATION,
  ON_PRE_CHAT_ANSWERED,
  ON_PRE_CHAT_ASKED,
  ON_VIDEO_CHAT_ANSWERED,
  ON_VIDEO_CHAT_REJECTED,
  OPEN,
  OPEN_WITH_MESSAGE,
  RECOGNIZE_USER,
  SHOW,
  TRACK_EVENT,
  UPDATE,
  UPDATE_ATTRIBUTE,
  USER_PROPERTIES,
  VISIBLE,
  VISIBLE_ON_MOBILE,
} from "constants/code";
import Code from "components/ui/base/Code";
import ImageHeader from "components/ui/base/ImageHeader";
import FeatureGrid from "components/ui/base/FeatureGrid";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.live-chat-api.seo.title")}
        description={t("pages.features.live-chat-api.seo.description")}
        image={{
          relativePath: "meta/live-chat-js-api.jpg",
          alt: "Live Chat JS API",
        }}
      />
      <ImageHeader
        title={t("pages.features.live-chat-api.title")}
        description={t("pages.features.live-chat-api.description")}
        image={{
          relativePath:
            "pages/features/live-chat-api/live-chat-js-sdk-api-customize.jpg",
          alt: "Customerly",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Live Chat API",
            path: "/features/live-chat-api/",
          },
        ]}
        circleBackground={{
          show: false,
        }}
        cta={{
          show: true,
        }}
      />

      <FeatureGrid
        title={t("pages.features.live-chat-api.featureGrid.title")}
        sections={[
          {
            title: t("pages.features.live-chat-api.featureGrid.userData.title"),
            description: t(
              "pages.features.live-chat-api.featureGrid.userData.description"
            ),
            links: [
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.userData.links.recognize"
                ),
                path: "#user-authentication",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.userData.links.userProperties"
                ),
                path: "#user-properties",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.userData.links.companyProperties"
                ),
                path: "#company-properties",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.userData.links.updateData"
                ),
                path: "#update-data",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.userData.links.updateUserProperty"
                ),
                path: "#update-user-property",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.userData.links.trackEvent"
                ),
                path: "#event-tracking",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.userData.links.logout"
                ),
                path: "#logout",
              },
            ],
          },
          {
            title: t(
              "pages.features.live-chat-api.featureGrid.customize.title"
            ),
            description: t(
              "pages.features.live-chat-api.featureGrid.customize.description"
            ),
            links: [
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.forceLocalization"
                ),
                path: "#force-localization",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.showLiveChat"
                ),
                path: "#show-live-chat",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.hideLiveChat"
                ),
                path: "#hide-live-chat",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.visibleLiveChat"
                ),
                path: "#visible-live-chat",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.visibleLiveChatOnMobile"
                ),
                path: "#visible-live-chat-on-mobile",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.openLiveChat"
                ),
                path: "#open-live-chat",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.openWithMessage"
                ),
                path: "#open-with-message",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.closeLiveChat"
                ),
                path: "#close-live-chat",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.chatPosition"
                ),
                path: "#chat-position",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.accentColor"
                ),
                path: "#accent-color",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.contrastColor"
                ),
                path: "#contrast-color",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.customize.links.attachments"
                ),
                path: "#attachments",
              },
            ],
          },
          {
            title: t(
              "pages.features.live-chat-api.featureGrid.callbacks.title"
            ),
            description: t(
              "pages.features.live-chat-api.featureGrid.callbacks.description"
            ),
            links: [
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onChatOpened"
                ),
                path: "#on-chat-opened",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onChatClosed"
                ),
                path: "#on-chat-closed",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onLeadGenerated"
                ),
                path: "#on-lead-generated",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onNewConversation"
                ),
                path: "#on-new-conversation",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onPreChatAnswered"
                ),
                path: "#on-pre-chat-answered",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onPreChatAsked"
                ),
                path: "#on-pre-chat-asked",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onVideoChatAnswered"
                ),
                path: "#on-video-chat-answered",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onVideoChatRejected"
                ),
                path: "#on-video-chat-rejected",
              },
              {
                text: t(
                  "pages.features.live-chat-api.featureGrid.callbacks.links.onHelpCenterArticle"
                ),
                path: "#on-help-center-article",
              },
            ],
          },
        ]}
      />

      <Code
        id={"user-authentication"}
        title={t("pages.features.live-chat-api.codes.userData.recognize.title")}
        description={t(
          "pages.features.live-chat-api.codes.userData.recognize.description"
        )}
      >
        {RECOGNIZE_USER}
      </Code>

      <Code
        id={"user-properties"}
        title={t(
          "pages.features.live-chat-api.codes.userData.userProperties.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.userData.userProperties.description"
        )}
      >
        {USER_PROPERTIES}
      </Code>

      <Code
        id={"company-properties"}
        title={t(
          "pages.features.live-chat-api.codes.userData.companyProperties.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.userData.companyProperties.description"
        )}
      >
        {COMPANY_PROPERTIES}
      </Code>

      <Code
        id={"update-data"}
        title={t(
          "pages.features.live-chat-api.codes.userData.updateData.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.userData.updateData.description"
        )}
      >
        {UPDATE}
      </Code>

      <Code
        id={"update-user-property"}
        title={t(
          "pages.features.live-chat-api.codes.userData.updateUserProperty.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.userData.updateUserProperty.description"
        )}
      >
        {UPDATE_ATTRIBUTE}
      </Code>

      <Code
        id={"event-tracking"}
        title={t(
          "pages.features.live-chat-api.codes.userData.trackEvent.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.userData.trackEvent.description"
        )}
      >
        {TRACK_EVENT}
      </Code>

      <Code
        id={"logout"}
        title={t("pages.features.live-chat-api.codes.userData.logout.title")}
        description={t(
          "pages.features.live-chat-api.codes.userData.logout.description"
        )}
      >
        {LOGOUT}
      </Code>

      <ImageWithDescription
        id={"customization"}
        title={t("pages.features.live-chat-api.codes.customize.title")}
        description={t("pages.features.live-chat-api.codes.customize.subtitle")}
        image={{
          relativePath: "pages/features/live-chat-api/customize.png",
          alt: t("pages.features.live-chat-api.codes.customize.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <Code
        id={"force-localization"}
        title={t(
          "pages.features.live-chat-api.codes.customize.forceLocalization.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.forceLocalization.description"
        )}
      >
        {FORCE_LOCALIZATION}
      </Code>

      <Code
        id={"show-live-chat"}
        title={t(
          "pages.features.live-chat-api.codes.customize.showLiveChat.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.showLiveChat.description"
        )}
      >
        {SHOW}
      </Code>

      <Code
        id={"hide-live-chat"}
        title={t(
          "pages.features.live-chat-api.codes.customize.hideLiveChat.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.hideLiveChat.description"
        )}
      >
        {HIDE}
      </Code>

      <Code
        id={"visible-live-chat"}
        title={t(
          "pages.features.live-chat-api.codes.customize.visibleLiveChat.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.visibleLiveChat.description"
        )}
      >
        {VISIBLE}
      </Code>

      <Code
        id={"visible-live-chat-on-mobile"}
        title={t(
          "pages.features.live-chat-api.codes.customize.visibleLiveChatOnMobile.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.visibleLiveChatOnMobile.description"
        )}
      >
        {VISIBLE_ON_MOBILE}
      </Code>

      <Code
        id={"open-live-chat"}
        title={t(
          "pages.features.live-chat-api.codes.customize.openLiveChat.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.openLiveChat.description"
        )}
      >
        {OPEN}
      </Code>

      <Code
        id={"open-with-message"}
        title={t(
          "pages.features.live-chat-api.codes.customize.openWithMessage.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.openWithMessage.description"
        )}
      >
        {OPEN_WITH_MESSAGE}
      </Code>

      <Code
        id={"close-live-chat"}
        title={t(
          "pages.features.live-chat-api.codes.customize.closeLiveChat.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.closeLiveChat.description"
        )}
      >
        {CLOSE}
      </Code>

      <Code
        id={"chat-position"}
        title={t(
          "pages.features.live-chat-api.codes.customize.chatPosition.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.chatPosition.description"
        )}
      >
        {CHAT_POSITION}
      </Code>

      <Code
        id={"accent-color"}
        title={t(
          "pages.features.live-chat-api.codes.customize.accentColor.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.accentColor.description"
        )}
      >
        {ACCENT_COLOR}
      </Code>

      <Code
        id={"contrast-color"}
        title={t(
          "pages.features.live-chat-api.codes.customize.contrastColor.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.contrastColor.description"
        )}
      >
        {CONTRAST_COLOR}
      </Code>

      <Code
        id={"attachments"}
        title={t(
          "pages.features.live-chat-api.codes.customize.attachments.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.customize.attachments.description"
        )}
      >
        {ATTACHMENTS_AVAILABLE}
      </Code>

      <ImageWithDescription
        id={"callbacks"}
        title={t("pages.features.live-chat-api.codes.callbacks.title")}
        description={t("pages.features.live-chat-api.codes.callbacks.subtitle")}
        image={{
          relativePath: "pages/features/live-chat-api/callbacks.png",
          alt: t("pages.features.live-chat-api.codes.callbacks.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <Code
        id={"on-chat-opened"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onChatOpened.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onChatOpened.description"
        )}
      >
        {ON_CHAT_OPENED}
      </Code>

      <Code
        id={"on-chat-closed"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onChatClosed.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onChatClosed.description"
        )}
      >
        {ON_CHAT_CLOSED}
      </Code>

      <Code
        id={"on-lead-generated"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onLeadGenerated.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onLeadGenerated.description"
        )}
      >
        {ON_LEAD_GENERATED}
      </Code>

      <Code
        id={"on-new-conversation"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onNewConversation.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onNewConversation.description"
        )}
      >
        {ON_NEW_CONVERSATION}
      </Code>

      <Code
        id={"on-pre-chat-asked"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onPreChatAsked.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onPreChatAsked.description"
        )}
      >
        {ON_PRE_CHAT_ASKED}
      </Code>

      <Code
        id={"on-pre-chat-answered"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onPreChatAnswered.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onPreChatAnswered.description"
        )}
      >
        {ON_PRE_CHAT_ANSWERED}
      </Code>

      <Code
        id={"on-video-chat-answered"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onVideoChatAnswered.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onVideoChatAnswered.description"
        )}
      >
        {ON_VIDEO_CHAT_ANSWERED}
      </Code>

      <Code
        id={"on-video-chat-rejected"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onVideoChatRejected.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onVideoChatRejected.description"
        )}
      >
        {ON_VIDEO_CHAT_REJECTED}
      </Code>

      <Code
        id={"on-help-center-article"}
        title={t(
          "pages.features.live-chat-api.codes.callbacks.onHelpCenterArticle.title"
        )}
        description={t(
          "pages.features.live-chat-api.codes.callbacks.onHelpCenterArticle.description"
        )}
      >
        {ON_HELP_CENTER_ARTICLE}
      </Code>

      <RegisterForCustomerService />
      <Customers />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
